/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

// Uncomment to copy all static images under .../metronic/images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import {image_pack_path} from './assets_manifest.js.erb'
window.image_pack_path = image_pack_path


// Be sure to use the latest version of jquery on the package.json

require('./sweet_alert_confirm')
window.jQuery = window.$ = require('jquery')

window.Rails = require("@rails/ujs")
window.Rails.start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.has_element = function has_element(elem) {
  return $(elem).length > 0
}

require("./app_settings.js")
require("./plugins.js")
require("./prismjs.js")
require("./styles.js")

$(document).on('turbolinks:load', () => {
  $('#browser-back').on('click', () => window.history.back())
})

