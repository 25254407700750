

function getAssetPath(apath=undefined) {
  if(apath == undefined) return apath

  
    return undefined;
  
}

export {getAssetPath as image_pack_path}